<template>
  <div>
    <Spinner :is-data-loaded="isDataLoaded" />
    <b-card v-if="isDataLoaded">
      <b-row >

        <b-col cols="12">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h2
              :class="{'mb-2':!showLink}"
              class="text-primary"
            >
              {{ $t('general_information_1') }}
            </h2>
            <!--            <div class="d-flex gap-10 flex-wrap-custom">-->
            <!--              <b-button-->
            <!--                v-if="isChangeStatus"-->
            <!--                v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
            <!--                variant="outline-secondary"-->
            <!--                @click="showModal()"-->
            <!--              >-->
            <!--                {{ $t('change_status') }}-->
            <!--              </b-button>-->
            <!--              <a-->
            <!--                v-if="showLink && (keys && status_change === 'approved')"-->
            <!--                :href="`https://aqaratic.digitalfuturedev.com/${$i18n.locale}/real-estate-details/${keys.slug}?customer=true`"-->
            <!--                target="_blank"-->
            <!--                class="btn btn-primary"-->
            <!--              >-->
            <!--                {{ $t('real_estate_link') }}-->
            <!--              </a>-->
            <!--              <b-button-->
            <!--                v-if="canDelete"-->
            <!--                v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
            <!--                variant="outline-danger"-->
            <!--                @click="showDeleteModal()"-->
            <!--              >-->
            <!--                {{ $t('delete_real_estate') }}-->
            <!--              </b-button>-->
            <!--            </div>-->
          </div>
        </b-col>
        <b-col
          v-for="(item,key,index) in general_information"
          v-if="key !== 'customer'&& key !== 'districts' && key !== 'actions' && key !== 'status'"
          :key="'item_'+index"
          cols="12"
          :md="customCols ? 6:4"
          :lg="customCols ? 6:3"
        >
          <h4 class="custom-line-clamp-one">
            {{ $t(key) }}
          </h4>
          <p>
            {{ item || '-' }}
          </p>
          <hr>
        </b-col>
        <b-col
          v-for="(item,key,index) in general_information"
          v-if="key === 'districts' && key !== 'actions' && key !== 'status'"
          :key="'item_'+index"
          cols="12"
          :md="customCols ? 6:4"
          :lg="customCols ? 6:3"
        >
          <h4 class="custom-line-clamp-one">
            {{ $t(key) }}
          </h4>
          <template v-if="key === 'districts'">
            <div class="" v-for="(district, districtIndex) in item" :key="'district_' + districtIndex">
                {{ (districtIndex + 1) + '- ' + district.name_label }}
            </div>
          </template>
          <p v-else>
            {{ item || '-' }}
          </p>
          <hr>
        </b-col>


      </b-row>

    </b-card>

    <b-card  v-if="isDataLoaded">

      <b-row >
        <b-col cols="12">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h2
              :class="{'mb-2':!showLink}"
              class="text-primary"
            >
              {{ $t('request_information') }}
            </h2>

          </div>
        </b-col>
        <b-col
          v-for="(item,key,index) in info_fields"
          v-if="key !== 'customer' && key !== 'actions' && key !== 'status'"
          :key="'item_'+index"
          cols="12"
          :md="customCols ? 6:4"
          :lg="customCols ? 6:3"
        >
          <h4 class="custom-line-clamp-one">
            {{ $t(key) }}
          </h4>
          <div v-if="Array.isArray(item)">
            <div>
              {{ $t('from') + ' ' + (item[0] || '-') }}
            </div>
            <div>
              {{ $t('to') + ' ' + (item[1] || '-') }}
            </div>
          </div>
          <template v-else>
            <p>
              {{ item || '-' }}
            </p>
          </template>
          <hr>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BEmbed } from 'bootstrap-vue'
import Spinner from '../../components/Spinner.vue'

export default {
  components: {
    BEmbed,
    Spinner,
  },
  data() {
    return {
      details: null,
      general_information: null,
      info_fields: null,
      districts: null,
      isDataLoaded: false,
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/real-estate-requests/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
          this.info_fields = res.data.data.info_fields_label
          this.districts = res.data.data.districts

          this.general_information = _.pick(this.details, [
            'title',
            'customer_name',
            'customer_mobile_without_code',
            'advertiser_phone',
            'the_sub_type_of_ad_label',
            'sub_type',
            'real_state_type_title',
            'status_value',
            'status',
            'type',
            'usage',
            'id',
            'city_name',
            'districts',

          ])
          this.isDataLoaded = true
        })
    }
  },
  methods: {},
}
</script>
